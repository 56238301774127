<script setup>
import HrbrInput from '@/components/ui/HrbrInput.vue';
import useFormValidation from '@/utils/composables/form-validation.js';
import { ToastProgrammatic as Toast } from 'buefy';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { computed, onMounted, ref } from 'vue';

const documentTypes = [
  {
    type: 'affidavit',
    label: 'Affidavit',
  },
  {
    type: 'agreement_deed',
    label: 'Agreement deed',
  },
  {
    type: 'agreement',
    label: 'Agreement',
  },
  {
    type: 'assignment',
    label: 'Assignment',
  },
  {
    type: 'assumption',
    label: 'Assumption',
  },
  {
    type: 'bond',
    label: 'Bond',
  },
  {
    type: 'certificate',
    label: 'Certificate',
  },
  {
    type: 'certificate_title',
    label: 'Certificate title',
  },
  {
    type: 'deed',
    label: 'Deed',
  },
  {
    type: 'dom',
    label: 'Dom',
  },
  {
    type: 'easement',
    label: 'Easement',
  },
  {
    type: 'joinder',
    label: 'Joinder',
  },
  {
    type: 'judgment_certified',
    label: 'Judgment certified',
  },
  {
    type: 'lien',
    label: 'Lien',
  },
  {
    type: 'mortgage',
    label: 'Mortgage',
  },
  {
    type: 'notice_of_commencement',
    label: 'Notice of commencement',
  },
  {
    type: 'note',
    label: 'Note',
  },
  {
    type: 'ordinance',
    label: 'Ordinance',
  },
  {
    type: 'plat',
    label: 'Plat',
  },
  {
    type: 'partial_release',
    label: 'Partial release',
  },
  {
    type: 'release',
    label: 'Release',
  },
  {
    type: 'resolution',
    label: 'Resolution',
  },
  {
    type: 'satisfaction',
    label: 'Satisfaction',
  },
  {
    type: 'subordination',
    label: 'Subordination',
  },
  {
    type: 'notice_of_termination',
    label: 'Notice of termination',
  },
  {
    type: 'vacation',
    label: 'Vacation',
  },
  {
    type: 'waiver',
    label: 'Waiver',
  },
];

const docTypesLanguage = {
  waiver: {
    providersTitle: 'Releasor',
    recipientsTitle: 'Releasee',
  },
  vacation: {
    providersTitle: 'Grantor',
    recipientsTitle: 'Grantee',
  },
  subordination: {
    providersTitle: 'Lender',
    recipientsTitle: 'Buyer',
  },
  satisfaction: {
    providersTitle: 'Lienor',
    recipientsTitle: 'Lienee',
  },
  resolution: {
    providersTitle: 'Enacting Body',
  },
  release: {
    providersTitle: 'Releasor',
    recipientsTitle: 'Releasee',
  },
  plat: {
    providersTitle: 'Surveyor',
    recipientsTitle: 'Property Owner',
  },
  partial_release: {
    providersTitle: 'Releasor',
    recipientsTitle: 'Releasee',
  },
  ordinance: {
    providersTitle: 'Enacting Body',
  },
  notice_of_termination: {
    providersTitle: 'Initiating Party',
    recipientsTitle: 'Affected Party',
  },
  notice_of_commencement: {
    providersTitle: 'Initiating Party',
    recipientsTitle: 'Affected Party',
  },
  note: {
    providersTitle: 'Payor',
    recipientsTitle: 'Payee',
  },
  mortgage: {
    providersTitle: 'Mortgagor',
    recipientsTitle: 'Mortgagee',
  },
  lien: {
    providersTitle: 'Lienor',
    recipientsTitle: 'Lienee',
  },
  judgment_certified: {
    providersTitle: 'Judgment Creditor',
    recipientsTitle: 'Judgment Debtor',
  },
  joinder: {
    providersTitle: 'Joining Parties',
  },
  easement: {
    providersTitle: 'Grantor',
    recipientsTitle: 'Grantee',
  },
  deed: {
    providersTitle: 'Grantor',
    recipientsTitle: 'Grantee',
  },
  dom: {
    providersTitle: 'Affiant',
  },
  certificate_title: {
    providersTitle: 'Holder',
  },
  certificate: {
    providersTitle: 'Holder',
  },
  bond: {
    providersTitle: 'Issuer',
    recipientsTitle: 'Bearer',
  },
  assumption: {
    providersTitle: 'Assuming Party',
    recipientsTitle: 'Releasing Party',
  },
  assignment: {
    providersTitle: 'Assignor',
    recipientsTitle: 'Assignee',
  },
  agreement: {
    providersTitle: 'Party A',
    recipientsTitle: 'Party B',
  },
  agreement_deed: {
    providersTitle: 'Party A',
    recipientsTitle: 'Party B',
  },
  affidavit: {
    providersTitle: 'Affiant',
  },
};

const considerationAmountTypes = ['deed', 'agreement_deed'];
const obligationAmountTypes = ['mortgage'];
const noRecipientTypes = [
  'affidavit',
  'dom',
  'resolution',
  'ordinance',
  'joinder',
  'certificate_title',
  'certificate',
];

const props = defineProps({
  integrationMetadata: {
    type: Object,
    default: () => {},
  },
  integrationData: {
    type: Object,
    default: () => {},
  },
  linkAutomation: {
    type: Object,
    default: () => {},
  },
  isManualTrigger: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'integration-parameters-selected',
  'integration-manual-trigger',
  'close',
]);
const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

const formOpened = ref(true);
const docTypeString = ref('');
const executionChecked = ref(false);
const statusChangeChecked = ref(false);
const isSuccessDisabled = computed(() => {
  executionChecked.value = false;
  return statusChangeChecked.value;
});
const inputRefs = ref([]);

const { errors, hasErrors, validate, clearError } = useFormValidation();

const filteredDocTypes = computed(() => {
  return documentTypes.filter((option) => {
    return option.label.toLowerCase().indexOf(docTypeString.value.toLowerCase()) >= 0;
  });
});
const formData = ref({
  selectedDocType: '',
  amount: null,
  category: '',
  providers: [
    {
      businessName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      nameSuffix: '',
      indicator: 'business',
    },
  ],
  recipients: [
    {
      businessName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      nameSuffix: '',
      indicator: 'business',
    },
  ],
});

onMounted(() => {
  if (props.linkAutomation?.custom_parameters) {
    const { custom_parameters, notification_settings } = props.linkAutomation;
    const docTypeObject = documentTypes.find(
      (item) => item.type === custom_parameters.document_type,
    );
    docTypeString.value = docTypeObject?.label || custom_parameters.document_type;
    formData.value = {
      selectedDocType: custom_parameters.document_type,
      amount: custom_parameters.total_amount,
      category: custom_parameters.category,
      providers: custom_parameters.providers.map((p) => ({
        businessName: p.full_name,
        firstName: p.first_name,
        lastName: p.last_name,
        middleName: p.middle_name,
        nameSuffix: p.name_suffix,
        indicator: p.person_type,
      })),
      recipients: custom_parameters.recipients?.map((p) => ({
        businessName: p.full_name,
        firstName: p.first_name,
        lastName: p.last_name,
        middleName: p.middle_name,
        nameSuffix: p.name_suffix,
        indicator: p.person_type,
      })) || [
        {
          businessName: '',
          firstName: '',
          lastName: '',
          middleName: '',
          nameSuffix: '',
          indicator: 'business',
        },
      ],
    };

    executionChecked.value = notification_settings.on_success;
    statusChangeChecked.value = notification_settings.on_status_change;
  }
});

const addParty = (type) => {
  formData.value[type].push({
    businessName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    nameSuffix: '',
    indicator: 'business',
  });
};

const removeRecipient = (index) => {
  formData.value.recipients.splice(index, 1);
  clearError(`recipient${index}Name`);
  clearError(`recipient${index}FirstName`);
  clearError(`recipient${index}LastName`);
};

const removeProvider = (index) => {
  formData.value.providers.splice(index, 1);
  clearError(`provider${index}Name`);
  clearError(`provider${index}FirstName`);
  clearError(`provider${index}LastName`);
};

const clearInputErrors = (index, type) => {
  clearError(`${type}${index}Name`);
  clearError(`${type}${index}FirstName`);
  clearError(`${type}${index}LastName`);
};

const save = () => {
  const isFormValid =
    inputRefs.value.every((ref) => !!ref.inputRef.value) && !!formData.value.selectedDocType;
  if (!isFormValid) {
    Toast.open({
      duration: 3000,
      message: 'Please, fill in all information',
      position: 'is-top',
      type: 'is-warning',
    });
    return;
  }

  if (props.isManualTrigger) {
    emit('integration-manual-trigger', {
      selectedTrigger: 'completed',
    });
    emit('close');
  }

  const amountTypes = [...obligationAmountTypes, ...considerationAmountTypes];
  const amount = formData.value.amount ? formData.value.amount.toString().replace(/[,$]/g, '') : null;

  const customParameters = {
    document_type: formData.value.selectedDocType,
    ...(amountTypes.includes(formData.value.selectedDocType) && { total_amount: parseFloat(amount) }),
    category: formData.value.category,
    providers: formData.value.providers.map((provider) => ({
      person_type: provider.indicator,
      ...(provider.indicator === 'individual' && {
        first_name: provider.firstName,
        last_name: provider.lastName,
        middle_name: provider.middleName,
        name_suffix: provider.nameSuffix,
      }),
      ...(provider.indicator === 'business' && {
        full_name: provider.businessName,
      }),
    })),
    recipients: formData.value.recipients.map((recipient) => ({
      person_type: recipient.indicator,
      ...(recipient.indicator === 'individual' && {
        first_name: recipient.firstName,
        last_name: recipient.lastName,
        middle_name: recipient.middleName,
        name_suffix: recipient.nameSuffix,
      }),
      ...(recipient.indicator === 'business' && {
        full_name: recipient.businessName,
      }),
    })),
  };

  if (noRecipientTypes.includes(formData.value.selectedDocType)) {
    delete customParameters.recipients;
  }

  emit('integration-parameters-selected', {
    integrationName: 'custom.catalis',
    selectedTrigger: 'completed',
    customParameters,
    notificationSettings: {
      on_success: executionChecked.value,
      on_status_change: statusChangeChecked.value,
    },
  });
  emit('close');
};

const onDocTypeSelected = (option) => {
  formData.value.selectedDocType = option ? option.type : '';
  if (
    !(
      considerationAmountTypes.includes(formData.value.selectedDocType) ||
      obligationAmountTypes.includes(formData.value.selectedDocType)
    )
  ) {
    clearError('amount');
  } else {
    formData.value.amount = null;
  }
};

const setInputRef = (el, refName) => {
  if (el) {
    inputRefs.value.push(el);
  } else {
    const elIndex = inputRefs.value.findIndex((el) => el.name === refName);
    inputRefs.value.splice(elIndex, 1);
  }
};
</script>
<template>
  <div
    class="hrbr-select-integration-options modal-card"
    style="min-height: 80%; z-index: 300; width: 100%">
    <section class="modal-card-body">
      <b-collapse class="card integration-settings-card" animation="slide" v-model="formOpened">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open">
            <div class="card-header-title">
              <div class="card-header-image">
                <img :src="integrationMetadata.icon" alt="logo" />
              </div>
              <div class="card-header-text">
                <p class="name">{{ integrationData.customIntegration.name }}</p>
                <p v-if="isManualTrigger">Manually triggered</p>
                <p v-else>Completed agreement</p>
              </div>
            </div>

            <a class="card-header-icon">
              <b-icon pack="fal" :icon="props.open ? 'circle-chevron-down' : 'circle-chevron-up'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="form">
            <div class="form-group-top">
              <div class="form-item autocomplete-item">
                <p class="form-label">Document type</p>
                <b-autocomplete
                  v-model="docTypeString"
                  placeholder="Select document type"
                  :data="filteredDocTypes"
                  field="label"
                  @select="onDocTypeSelected">
                </b-autocomplete>
              </div>

              <hrbr-input
                v-if="
                  considerationAmountTypes.includes(formData.selectedDocType) ||
                  obligationAmountTypes.includes(formData.selectedDocType)
                "
                v-model="formData.amount"
                :ref="(el) => setInputRef(el, 'amount')"
                :error-message="errors['amount'] && errors['amount'][0]"
                :label="
                  considerationAmountTypes.includes(formData.selectedDocType)
                    ? 'Consideration amount'
                    : 'Obligation amount'
                "
                :mask="currencyMask"
                class="amount-input"
                name="amount"
                @input="validate('amount', formData.amount, ['required', 'currency'], 'Amount')"
                @focusout="validate('amount', formData.amount, ['required', 'currency'], 'Amount')" />

              <hrbr-input
                v-model="formData.category"
                label="Category"
                class="amount-input"
                name="category"
              />
            </div>

            <div class="form-columns">
              <div class="form-column">
                <h3>
                  {{ docTypesLanguage[formData.selectedDocType]?.providersTitle || 'Grantor' }}
                </h3>

                <div
                  v-for="(provider, index) in formData.providers"
                  :class="['form-group', index === 0 && 'default-group']">
                  <div class="form-fields">
                    <div class="form-item">
                      <p class="form-label">Identification</p>

                      <b-select
                        v-model="provider.indicator"
                        @input="clearInputErrors(index, 'provider')"
                      >
                        <option value="business">Business</option>
                        <option value="individual">Individual</option>
                      </b-select>
                    </div>

                    <template v-if="provider.indicator === 'individual'">
                      <hrbr-input
                        v-model="provider.firstName"
                        :ref="(el) => setInputRef(el, `provider${index}FirstName`)"
                        :error-message="
                          errors[`provider${index}FirstName`] &&
                          errors[`provider${index}FirstName`][0]
                        "
                        :name="`provider${index}FirstName`"
                        label="First name"
                        @input="
                          validate(
                            `provider${index}FirstName`,
                            provider.firstName,
                            ['required'],
                            'First name',
                          )
                        "
                        @focusout="
                          validate(
                            `provider${index}FirstName`,
                            provider.firstName,
                            ['required'],
                            'First name',
                          )
                        " />

                      <hrbr-input
                        v-model="provider.lastName"
                        :ref="(el) => setInputRef(el, `provider${index}LastName`)"
                        :error-message="
                          errors[`provider${index}LastName`] &&
                          errors[`provider${index}LastName`][0]
                        "
                        :name="`provider${index}LastName`"
                        label="Last name"
                        @input="
                          validate(
                            `provider${index}LastName`,
                            provider.lastName,
                            ['required'],
                            'Last name',
                          )
                        "
                        @focusout="
                          validate(
                            `provider${index}LastName`,
                            provider.lastName,
                            ['required'],
                            'Last name',
                          )
                        " />

                      <hrbr-input v-model="provider.middleName" label="Middle name" />

                      <hrbr-input v-model="provider.nameSuffix" label="Name suffix" />
                    </template>

                    <hrbr-input
                      v-if="provider.indicator === 'business'"
                      v-model="provider.businessName"
                      :ref="(el) => setInputRef(el, `provider${index}Name`)"
                      :error-message="
                        errors[`provider${index}Name`] && errors[`provider${index}Name`][0]
                      "
                      :name="`provider${index}Name`"
                      label="Name"
                      @input="
                        validate(
                          `provider${index}Name`,
                          provider.businessName,
                          ['required'],
                          'Name',
                        )
                      "
                      @focusout="
                        validate(
                          `provider${index}Name`,
                          provider.businessName,
                          ['required'],
                          'Name',
                        )
                      " />
                  </div>
                  <div class="form-actions" v-if="index > 0">
                    <div class="form-action" @click="removeProvider(index)">
                      <i class="fa-light fa-trash"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="action-item" @click="addParty('providers')">
                <i class="fa-light fa-circle-plus"></i>
              </div>

              <div v-if="!noRecipientTypes.includes(formData.selectedDocType)" class="form-column">
                <h3>
                  {{ docTypesLanguage[formData.selectedDocType]?.recipientsTitle || 'Grantee' }}
                </h3>

                <div
                  v-for="(recipient, index) in formData.recipients"
                  :class="['form-group', index === 0 && 'default-group']">
                  <div class="form-fields">
                    <div class="form-item">
                      <p class="form-label">Identification</p>

                      <b-select
                        v-model="recipient.indicator"
                        @input="clearInputErrors(index, 'recipient')"
                      >
                        <option value="business">Business</option>
                        <option value="individual">Individual</option>
                      </b-select>
                    </div>

                    <template v-if="recipient.indicator === 'individual'">
                      <hrbr-input
                        v-model="recipient.firstName"
                        :ref="(el) => setInputRef(el, `recipient${index}FirstName`)"
                        :error-message="
                          errors[`recipient${index}FirstName`] &&
                          errors[`recipient${index}FirstName`][0]
                        "
                        :name="`recipient${index}FirstName`"
                        label="First name"
                        @input="
                          validate(
                            `recipient${index}FirstName`,
                            recipient.firstName,
                            ['required'],
                            'First name',
                          )
                        "
                        @focusout="
                          validate(
                            `recipient${index}FirstName`,
                            recipient.firstName,
                            ['required'],
                            'First name',
                          )
                        " />

                      <hrbr-input
                        v-model="recipient.lastName"
                        :ref="(el) => setInputRef(el, `recipient${index}LastName`)"
                        :error-message="
                          errors[`recipient${index}LastName`] &&
                          errors[`recipient${index}LastName`][0]
                        "
                        :name="`recipient${index}LastName`"
                        label="Last name"
                        @input="
                          validate(
                            `recipient${index}LastName`,
                            recipient.lastName,
                            ['required'],
                            'Last name',
                          )
                        "
                        @focusout="
                          validate(
                            `recipient${index}LastName`,
                            recipient.lastName,
                            ['required'],
                            'Last name',
                          )
                        " />

                      <hrbr-input v-model="recipient.middleName" label="Middle name" />

                      <hrbr-input v-model="recipient.nameSuffix" label="Name suffix" />
                    </template>

                    <hrbr-input
                      v-if="recipient.indicator === 'business'"
                      v-model="recipient.businessName"
                      :ref="(el) => setInputRef(el, `recipient${index}Name`)"
                      :error-message="
                        errors[`recipient${index}Name`] &&
                        errors[`recipient${index}Name`][0]
                      "
                      :name="`recipient${index}Name`"
                      label="Name"
                      @input="
                        validate(
                          `recipient${index}Name`,
                          recipient.businessName,
                          ['required'],
                          'Name',
                        )
                      "
                      @focusout="
                        validate(
                          `recipient${index}Name`,
                          recipient.businessName,
                          ['required'],
                          'Name',
                        )
                      " />
                  </div>
                  <div class="form-actions" v-if="index > 0">
                    <div class="form-action" @click="removeRecipient(index)">
                      <i class="fa-light fa-trash"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!noRecipientTypes.includes(formData.selectedDocType)"
            class="action-item"
            @click="addParty('recipients')">
            <i class="fa-light fa-circle-plus"></i>
          </div>
        </div>
      </b-collapse>

      <div class="card notifications-card integration-settings-card">
        <div class="card-content">
          <h3>Notifications</h3>

          <div class="checkboxes">
            <b-checkbox v-model="statusChangeChecked"> Email me on status changes </b-checkbox>
            <b-checkbox v-model="executionChecked" :disabled="isSuccessDisabled">
              Email me on success
            </b-checkbox>

            <p><small>* Failed executions will always be notified</small></p>
          </div>

          <b-button
            :disabled="hasErrors"
            :label="isManualTrigger ? 'Send' : 'Save'"
            type="is-success"
            @click="save" />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-select-integration-options {
  &.modal-card,
  .modal-card-body {
    overflow: scroll;
  }

  .modal-card-body {
    display: flex;
    flex-direction: column;
    width: 600px !important;
  }

  .content-right-aligned {
    text-align: right;
  }

  .dropdown-menu {
    z-index: 100;
  }

  .form {
    >>> .input-label {
      margin-bottom: 2px;
    }
    >>> .select select {
      padding: 9px 13px;
      font-size: 13px;
      line-height: 20px;
      border-radius: 5px;
      box-shadow: none;

      &:focus {
        box-shadow: none;
        border-color: rgba(47, 90, 137, 0.6);
      }
    }

    &-item,
    .input-container {
      margin-bottom: 15px;
    }

    &-label {
      margin-bottom: 2px;
      font-size: 16px;
      color: var(--label-text);
      font-weight: 600;
      letter-spacing: 0.08px;
    }

    &-columns {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-column {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 15px;

      h3 {
        width: 100%;
        margin-bottom: 5px;
        font-size: 17px;
        font-weight: 500;
        color: #000;
      }

      .form-item,
      .input-container {
        width: 49%;
      }
    }

    &-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid #dbdbdba1;
      }
    }

    &-fields {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:not(:last-child) {
        width: calc(100% - 25px);
      }
    }

    &-actions {
      padding-top: 13px;
    }

    &-action {
      cursor: pointer;
    }

    &-group-top {
      width: calc(100% - 25px);
    }

    .default-group {
      max-width: calc(100% - 25px);
    }
  }

  .autocomplete {
    >>> input {
      height: auto;
      padding: 9px 13px;
      font-size: 13px;
      line-height: 20px;
      border-radius: 5px;
      box-shadow: none;

      &:focus {
        box-shadow: none;
        border-color: rgba(47, 90, 137, 0.6);
      }
    }
  }

  .card + .card {
    margin-top: 15px;
  }

  .notifications-card {
    h3 {
      font-weight: 700;
      color: #363636;
    }

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 20px 0;
    }

    >>> .b-checkbox .check {
      box-shadow: none;
      border-width: 1px;
      border-color: #dbdbdb;
    }
  }

  .action-item {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    padding: 7px 10px;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }

  .amount-input {
    >>> .left-icon {
      color: #000;
    }
  }
}
</style>
